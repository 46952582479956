// /*
//  * @Author: gaurav
//  * @Date:   2020-07-07 22:27:26
//  * @Last Modified by: gaurav
//  * @Last Modified time: 2020-07-29 03:40:15
//  */
// import React, { useEffect, useState } from "react"
// import { GlobalStyles, mediaBreakpoints } from "../styles/styles"
// import Layout from "../components/layout.bak.js"
// import styled from "@emotion/styled"
// import { css } from "@emotion/react"
// import SEO from "../components/SEO"
// import parse from "html-react-parser"
// import TwitterLogo from "../images/twitter.png"
// import BackgroundImage from "gatsby-background-image"
// import { Link } from "gatsby"
// import useHome from "../hooks/use-home"
// import Img from "gatsby-image"
// import { newGlobalStyles } from "../styles/newstyles"

// const Image = styled(Img)`
//   object-fit: contain;
//   height: 100%;
//   width: 100%;
//   position: relative;
// `

// const {
//   small,
//   medium,
//   semiLargeAndLarge,
//   smallAndMedium,
//   ipad,
//   ipadLandscape,
//   ipadPro,
//   semiLarge,
//   xxLarge,
// } = mediaBreakpoints

// const HomePageContainer = styled.div`
//   width: 100%;
//   height: 100vh;
//   min-height: 650px;
//   display: flex;
//   flex-direction: column;
//   @media ${smallAndMedium} {
//     flex-direction: column;
//     overflow: auto;
//     height: auto;
//   }
// `

// const LeftSectionContainer = styled.div`
//   width: 51.2%;
//   height: 100%;
//   @media ${smallAndMedium} {
//     width: 100%;
//   }
//   @media only all and (min-width: 1802px) and (max-width: 2022px) {
//     width: 46.9%;
//   }
// `
// const RightSectionContainer = styled.div`
//   width: 58%;
//   height: 100%;
//   @media ${smallAndMedium} {
//     width: 100%;
//   }
// `
// const MainSectionCard = styled.div`
//   height: ${props =>
//     props && props.array.length < 2
//       ? "100%"
//       : props.index === 0
//       ? "75%"
//       : "25%"};
//   width: 100%;
//   overflow: hidden;
//   transition: 5s;
//   background-color: ${props =>
//     props && props.item && props.item.bgcolor ? props.item.bgcolor : "#fff"};
//   @media ${smallAndMedium} {
//     height: ${props => (props && props.mobHeight ? props.mobHeight : "auto")};
//     width: 100%;
//   }
// `
// const SectionCardWrapper = styled.div`
//   width: 100%;
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-end;
//   padding: 2.75rem 6rem 2.75rem 3vw;
//   @media ${medium} {
//     padding: 2rem;
//   }
//   @media ${small} {
//     padding: 5%;
//     padding-bottom: 45px;
//   }
//   @media ${ipadPro} {
//     padding: 1.5rem;
//   }
//   @media ${ipadLandscape} {
//     padding: 1.5rem;
//   }
//   @media ${semiLarge} {
//     padding: 1.5rem 3rem 1.5rem 3vw;
//   }
// `
// const MainSectionTitle = styled.h3`
//   color: #000;
//   margin-bottom: 0.5rem;
//   @media ${small} {
//     font-size: 2.5rem;
//     margin-bottom: 1rem;
//   }
//   @media ${xxLarge} {
//     width: 30rem;
//   }
// `

// const SideSectionContainer = styled.div`
//   width: 100%;
//   height: 100%;
//   display: flex;
//   flex-wrap: wrap;
//   color: #ffffff;
//   @media ${smallAndMedium} {
//     width: 100%;
//   }
// `
// const UpperSection = styled.div`
//   width: 100%;
//   height: 75vh;
//   display: flex;
//   flex-direction: row;
//   @media ${smallAndMedium} {
//     flex-direction: column;
//     overflow: auto;
//     height: auto;
//   }
// `
// const BottomSectionContainer = styled.div`
//   width: 100%;
//   height: 28.5vh;
//   display: flex;
//   @media ${smallAndMedium} {
//     width: 100%;
//     height: 100%;
//     flex-direction: column;
//   }
// `
// const TagLineContent = css`
//   color: #565656;
//   & > p {
//     margin: 0;
//     & > a {
//       color: blue;
//     }
//   }
// `
// const SideSectionCard = styled.div`
//   height: ${props => (props && props.array.length < 3 ? "95%" : "50%")};
//   overflow: hidden;
//   width: ${props =>
//     props &&
//     props.array &&
//     props.array.length === 4 &&
//     (props.index === 0 || props.index === 2)
//       ? "50%"
//       : props.array.length === 4 && (props.index === 1 || props.index === 3)
//       ? "50%"
//       : props.array.length === 3 && props.index === 0
//       ? "50%"
//       : props.array.length === 3 && props.index === 1
//       ? "50%"
//       : props.array.length === 3 && props.index === 2
//       ? "100%"
//       : props.array.length === 2 && props.index === 0
//       ? "50%"
//       : props.array.length === 2 && props.index === 1
//       ? "50%"
//       : "100%"};
//   background-color: ${props =>
//     props && props.item && props.item.bgcolor ? props.item.bgcolor : "#fff"};
//   min-height: ${props =>
//     props && (props.index === 0 || props.index === 1) ? "15rem" : "unset"};
//   @media ${smallAndMedium} {
//     height: ${props =>
//       (props && props.index === 0) || props.index === 1 ? "25rem" : "24rem"};
//     width: 100%;
//   }
// `
// const SideSectionTitle = styled.h5`
//   color: ${props =>
//     props && props.item && props.item.color ? props.item.color : "#010c0f"};
//   width: ${props =>
//     props && (props.index === 1 || props.index === 3) ? "100%" : "82%"};
//   font-size: 24px;
//   @media ${smallAndMedium} {
//     width: 100%;
//   }
//   @media ${small} {
//     font-size: 24px;
//   }
//   @media ${ipadLandscape} {
//     width: 100%;
//     font-size: 20px;
//   }
//   @media only all and (min-width: 1802px) and (max-width: 2280px) {
//     width: 72%;
//     font-size: 24px;
//   }
//   @media only all and (min-width: 2280px) {
//     width: 72%;
//     font-size: 24px;
//     // padding-top: 3.5% !important;
//   }
// `
// const BottomSectionCard = styled.div`
//   height: 100%;
//   overflow: hidden;
//   width: ${props =>
//     props.array && props.array.length === 1
//       ? "100%"
//       : props.array.length === 2
//       ? "25%"
//       : "50%"};
//   z-index: 1;
//   background-color: ${props =>
//     props && props.item && props.item.bgcolor ? props.item.bgcolor : "#fff"};
//   @media ${smallAndMedium} {
//     height: ${props => (props && props.mobHeight ? props.mobHeight : "226px")};
//     width: 100%;
//   }
// `
// const ProductImageBackgroundImage = styled(BackgroundImage)`
//   background-size: cover;
//   object-fit: contain;
//   height: 100%;
//   width: 100%;
//   position: relative;
//   height: 100%;
//   transition: all 0.4s ease !important;
//   &::before,
//   &::after {
//     transition: all 0.4s ease !important;
//     -webkit-backface-visibility: hidden;
//   }
//   &:hover {
//     opacity: 0.8 !important;
//     &::before,
//     &::after {
//       transform: scale(1.03);
//       overflow: hidden;
//     }
//   }
// `

// const colorChartImage = css`
//   height: 1.125rem;
//   width: 8rem;
//   margin-bottom: 3.75rem;
// `
// const sizeChartImage = css`
//   height: 2.125rem;
//   width: 13.5rem;
//   margin-bottom: 1rem;
// `
// const productLogo = css`
//   height: 2.5rem;
//   width: 5.125rem;
// `

// const productLogoWrapper = css`
//   position: absolute;
//   top: 2.5rem;
//   left: 3vw @media ${small} {
//     left: 1.5rem;
//   }
//   @media ${medium} {
//     left: 2rem;
//   }
// `
// const logoImageStyle = css`
//   margin-bottom: 0.5rem;
//   height: 16px;
//   @media ${medium} {
//     height: 16px;
//   }
//   @media ${small} {
//     height: 16px;
//   }
// `

// const IndexPage = () => {
//   const homeData = useHome()
//   let width, setWidth
//   // if (typeof window !== "undefined")
//     [width, setWidth] = useState(window.innerWidth)
//   const mainSectionArray = []
//   const sideSectionArray = []
//   const bottomArray = []

//   useEffect(() => {
//     setWidth(window.innerWidth)
//   }, [])

//   useEffect(() => {
//     window.addEventListener("resize", updateWidthAndHeight)
//     return () => window.removeEventListener("resize", updateWidthAndHeight)
//   })

//   const updateWidthAndHeight = () => {
//     setWidth(window.innerWidth)
//   }

//   homeData.map(item => {
//     if (+item.sortorder < 3) {
//       mainSectionArray.push(item)
//     }
//     if (+item.sortorder > 2 && +item.sortorder < 7) {
//       sideSectionArray.push(item)
//     }
//     if (+item.sortorder > 6) {
//       bottomArray.push(item)
//     }
//   })

//   mainSectionArray.sort((a, b) => {
//     if (+a.sortorder < +b.sortorder) return -1
//     if (+a.sortorder > +b.sortorder) return 1
//     return 0
//   })

//   sideSectionArray.sort((a, b) => {
//     if (+a.sortorder < +b.sortorder) return -1
//     if (+a.sortorder > +b.sortorder) return 1
//     return 0
//   })

//   bottomArray.sort((a, b) => {
//     if (+a.sortorder < +b.sortorder) return -1
//     if (+a.sortorder > +b.sortorder) return 1
//     return 0
//   })

//   if (width < 992) {
//     let b = sideSectionArray[1]
//     sideSectionArray[1] = sideSectionArray[2]
//     sideSectionArray[2] = b
//   }

//   const logoStyle = {
//     opacity: 1,
//     transition: "opacity 1s ease-in",
//   }

//   const renderImage = (bgImage, bgMobileImge) => {
//     let sources = [
//       bgImage,
//       {
//         ...bgImage,
//         media: `(min-width: 768px)`,
//       },
//     ]
//     if (bgMobileImge)
//       sources = [
//         bgMobileImge,
//         {
//           ...bgImage,
//           media: `(min-width: 768px)`,
//         },
//       ]
//     return sources
//   }

//   return (
//     <Layout theme="light" noFooter={false} overflow="hidden" h="100vh">
//       <SEO
//         title="Piktorlabs | Home"
//         description="Piktorlabs home page"
//         pathname="/"
//         article={false}
//       />
//       <HomePageContainer>
//         <UpperSection>
//           <LeftSectionContainer>
//             {mainSectionArray.length > 0 &&
//               mainSectionArray.map((item, index) => {
//                 if (item.bgimage) {
//                   return (
//                     <MainSectionCard
//                       array={mainSectionArray}
//                       index={index}
//                       item={item}
//                       mobHeight={"51.625rem"}
//                     >
//                       <ProductImageBackgroundImage
//                         css={css`
//                           object-fit: contain;
//                         `}
//                         fluid={renderImage(item.bgimage, item.mobileBgImage)}
//                         alt="img-piktorlabs-header"
//                         smallHeight={"51.625rem"}
//                       >
//                         <Link
//                           css={css`
//                             width: 100%;
//                             height: 100%;
//                           `}
//                           to={item.link ? item.link : "/"}
//                         >
//                           <SectionCardWrapper
//                             css={css`
//                               position: relative;
//                               @media ${ipadPro} {
//                                 padding: 11.5%;
//                               }
//                             `}
//                           >
//                             {item.productlogo && (
//                               <div css={productLogoWrapper}>
//                                 <Img
//                                   css={productLogo}
//                                   fluid={item.productlogo}
//                                 />
//                               </div>
//                             )}
//                             {item.sizechart && (
//                               <Img
//                                 css={sizeChartImage}
//                                 fluid={item.sizechart}
//                               />
//                             )}
//                             {item.colorchart && (
//                               <Img
//                                 css={colorChartImage}
//                                 fluid={item.colorchart}
//                               />
//                             )}
//                             {item.product_logo_image && (
//                               <Img
//                                 fluid={item.product_logo_image}
//                                 imgStyle={{
//                                   "object-fit": "contain",
//                                   width: "unset",
//                                 }}
//                                 css={logoImageStyle}
//                               />
//                             )}
//                             {item.headertext && (
//                               <newGlobalStyles.body2
//                                 css={css`
//                                   text-transform: uppercase;
//                                   margin-bottom: 0.5rem;
//                                   font-weight: bold;
//                                   color: #434343;
//                                   line-height: 1.14;
//                                 `}
//                               >
//                                 {item.headertext}
//                               </newGlobalStyles.body2>
//                             )}
//                             {item.sectiontitle && (
//                               <MainSectionTitle>
//                                 {item.sectiontitle}
//                               </MainSectionTitle>
//                             )}
//                             {item.buttonvalue && (
//                               <Link to={item.link ? item.link : "/"}>
//                                 <newGlobalStyles.submitButton
//                                   color="#3452f2"
//                                   bgColor="#fff"
//                                   align="left"
//                                   padding="0 0"
//                                   hoverColor="#6c63ff"
//                                   hoverBgColor="#fff"
//                                   border="none"
//                                 >
//                                   {item.buttonvalue}
//                                 </newGlobalStyles.submitButton>
//                               </Link>
//                             )}
//                             {item.tagline && (
//                               <newGlobalStyles.body2 css={TagLineContent}>
//                                 {parse(item.tagline)}
//                               </newGlobalStyles.body2>
//                             )}
//                           </SectionCardWrapper>
//                         </Link>
//                       </ProductImageBackgroundImage>
//                     </MainSectionCard>
//                   )
//                 } else
//                   return (
//                     <MainSectionCard
//                       array={mainSectionArray}
//                       index={index}
//                       item={item}
//                       mobHeight={"430px"}
//                       css={css`
//                         min-height: 15rem;
//                       `}
//                     >
//                       <SectionCardWrapper
//                         css={css`
//                           @media ${smallAndMedium} {
//                             justify-content: center;
//                           }
//                           @media ${ipadPro} {
//                             padding: 11.5%;
//                           }
//                           @media ${small} {
//                             padding-bottom: 10px;
//                           }
//                         `}
//                       >
//                         {item.product_logo_image && (
//                           <Img
//                             fluid={item.product_logo_image}
//                             imgStyle={{
//                               "object-fit": "contain",
//                               width: "unset",
//                             }}
//                             css={logoImageStyle}
//                           />
//                         )}
//                         {item.headertext && (
//                           <newGlobalStyles.body2
//                             css={css`
//                               text-transform: uppercase;
//                               margin-bottom: 0.5rem;
//                               font-weight: bold;
//                               color: #fff;
//                               line-height: 1.14;
//                             `}
//                           >
//                             {item.headertext}
//                           </newGlobalStyles.body2>
//                         )}
//                         {item.sectiontitle && (
//                           <MainSectionTitle
//                             css={css`
//                               margin-bottom: 2.5rem;
//                               font-size: 3.8rem;
//                               width: 100%;
//                               @media ${small} {
//                                 width: 81%;
//                               }
//                               @media ${smallAndMedium} {
//                                 margin-bottom: 3rem;
//                                 margin-top: 5rem;
//                               }
//                               @media ${ipad} {
//                                 font-size: 3.75rem;
//                                 width: 100%;
//                               }
//                               ++++@media ${ipadPro} {
//                                 font-size: 5rem;
//                                 width: 100%;
//                               }
//                               @media ${ipadLandscape} {
//                                 font-size: 4.5rem;
//                               }
//                               @media ${xxLarge} {
//                                 font-size: 5rem;
//                                 width: 45rem;
//                               }
//                             `}
//                           >
//                             {item.sectiontitle}
//                           </MainSectionTitle>
//                         )}
//                         {item.buttonvalue && (
//                           <Link to={item.link ? item.link : "/"}>
//                             <newGlobalStyles.submitButton
//                               color="#3452f2"
//                               bgColor="#fff"
//                               align="left"
//                               padding="0 0"
//                               hoverColor="#6c63ff"
//                               hoverBgColor="#fff"
//                               border="none"
//                             >
//                               {item.buttonvalue}
//                             </newGlobalStyles.submitButton>
//                           </Link>
//                         )}
//                         {item.tagline && (
//                           <newGlobalStyles.body2 css={TagLineContent}>
//                             {parse(item.tagline)}
//                           </newGlobalStyles.body2>
//                         )}
//                       </SectionCardWrapper>
//                     </MainSectionCard>
//                   )
//               })}
//           </LeftSectionContainer>
//           <RightSectionContainer>
//             <SideSectionContainer>
//               {sideSectionArray.length > 0 &&
//                 sideSectionArray.map((item, index) => {
//                   if (item.bgimage) {
//                     return (
//                       <SideSectionCard
//                         array={sideSectionArray}
//                         index={index}
//                         item={item}
//                       >
//                         <ProductImageBackgroundImage
//                           css={css`
//                             object-fit: contain;
//                           `}
//                           fluid={renderImage(item.bgimage, item.mobileBgImage)}
//                           alt="img-piktorlabs-header"
//                         >
//                           <Link
//                             css={css`
//                               width: 100%;
//                               height: 100%;
//                             `}
//                             to={item.link ? item.link : "/"}
//                           >
//                             <SectionCardWrapper
//                               css={css`
//                                 padding: 2.5rem;
//                                 @media ${medium} {
//                                   padding: 2rem;
//                                 }
//                                 @media ${small} {
//                                   padding: 5%;
//                                   padding-bottom: 45px;
//                                 }
//                               `}
//                               index={index}
//                             >
//                               {item.product_logo_image && (
//                                 <Img
//                                   fluid={item.product_logo_image}
//                                   imgStyle={{
//                                     "object-fit": "contain",
//                                     width: "unset",
//                                   }}
//                                   css={logoImageStyle}
//                                 />
//                               )}
//                               {item.headertext && (
//                                 <newGlobalStyles.body2
//                                   css={css`
//                                     text-transform: uppercase;
//                                     font-weight: bold;
//                                     font-size: 14px;
//                                     margin-bottom: 0.5rem;
//                                     color: #fff;
//                                     line-height: 1.14;
//                                     width: max-content;
//                                     @media ${xxLarge} {
//                                       font-size: 14px;
//                                     }
//                                   `}
//                                 >
//                                   {item.headertext}
//                                 </newGlobalStyles.body2>
//                               )}
//                               {item.blogheader && (
//                                 <SideSectionTitle
//                                   css={css`
//                                     @media ${small} {
//                                       max-width: 385px;
//                                     }
//                                   `}
//                                   item={item}
//                                   index={index}
//                                 >
//                                   {item.blogheader}
//                                 </SideSectionTitle>
//                               )}
//                               {item.sectiontitle && (
//                                 <SideSectionTitle
//                                   item={item}
//                                   index={index}
//                                   css={css`
//                                     @media ${small} {
//                                       max-width: 375px;
//                                     }
//                                   `}
//                                 >
//                                   {item.sectiontitle}
//                                 </SideSectionTitle>
//                               )}
//                               {item.buttonvalue && (
//                                 <Link to={item.link ? item.link : "/"}>
//                                   <newGlobalStyles.submitButton
//                                     color="#3452f2"
//                                     bgColor="#fff"
//                                     align="left"
//                                     padding="0 0"
//                                     hoverColor="#3452f2"
//                                     hoverBgColor="#5451ff"
//                                     activeBgColor="#8381ff"
//                                     border="none"
//                                     css={css`
//                                       min-width: 9.2rem;
//                                     `}
//                                   >
//                                     {item.buttonvalue}
//                                   </newGlobalStyles.submitButton>
//                                 </Link>
//                               )}
//                               {item.tagline && (
//                                 <newGlobalStyles.body2 css={TagLineContent}>
//                                   {parse(item.tagline)}
//                                 </newGlobalStyles.body2>
//                               )}
//                             </SectionCardWrapper>
//                           </Link>
//                         </ProductImageBackgroundImage>
//                       </SideSectionCard>
//                     )
//                   } else {
//                     return (
//                       <SideSectionCard
//                         array={sideSectionArray}
//                         index={index}
//                         item={item}
//                         mobHeight={"40vh"}
//                       >
//                         <SectionCardWrapper
//                           css={css`
//                             padding: 2.5rem;
//                             @media ${medium} {
//                               padding: 2rem;
//                             }
//                             @media ${small} {
//                               padding: 5%;
//                               padding-bottom: 52px;
//                             }
//                           `}
//                         >
//                           {item.product_logo_image && (
//                             <Img
//                               fluid={item.product_logo_image}
//                               imgStyle={{
//                                 "object-fit": "contain",
//                                 width: "unset",
//                               }}
//                               css={logoImageStyle}
//                             />
//                           )}
//                           {item.headertext && (
//                             <newGlobalStyles.body2
//                               css={css`
//                                 text-transform: uppercase;
//                                 font-weight: bold;
//                                 font-size: 14px;
//                                 margin-bottom: 0.5rem;
//                                 color: #343434;
//                                 line-height: 1.14;
//                                 width: max-content;
//                                 @media ${xxLarge} {
//                                   font-size: 14px;
//                                 }
//                               `}
//                             >
//                               {item.headertext}
//                             </newGlobalStyles.body2>
//                           )}
//                           {item.blogheader && (
//                             <SideSectionTitle
//                               css={css`
//                                 @media ${small} {
//                                   max-width: 300px;
//                                 }
//                               `}
//                               item={item}
//                               index={index}
//                             >
//                               {item.blogheader}
//                             </SideSectionTitle>
//                           )}
//                           {item.sectiontitle && (
//                             <SideSectionTitle
//                               css={css`
//                                 margin-bottom: 8px;
//                                 @media ${small} {
//                                   max-width: 300px;
//                                 }
//                                 @media ${xxLarge} {
//                                   font-size: 24px;
//                                 }
//                               `}
//                               item={item}
//                               index={index}
//                             >
//                               {item.sectiontitle}
//                             </SideSectionTitle>
//                           )}
//                           {item.buttonvalue && (
//                             <Link to={item.link ? item.link : "/"}>
//                               <newGlobalStyles.submitButton
//                                 color="#f5f5f5"
//                                 bgColor="#000"
//                                 padding="0.75rem 1.0625rem"
//                                 hoverColor="#f5f5f5"
//                                 hoverBgColor="#5451ff"
//                                 activeBgColor="#8381ff"
//                                 border="none"
//                                 css={css`
//                                   min-width: 9.2rem;
//                                 `}
//                               >
//                                 {item.buttonvalue}
//                               </newGlobalStyles.submitButton>
//                             </Link>
//                           )}
//                           {item.tagline && (
//                             <newGlobalStyles.body2 css={TagLineContent}>
//                               {parse(item.tagline)}
//                             </newGlobalStyles.body2>
//                           )}
//                         </SectionCardWrapper>
//                       </SideSectionCard>
//                     )
//                   }
//                 })}
//             </SideSectionContainer>
//           </RightSectionContainer>
//         </UpperSection>
//         <BottomSectionContainer>
//           {bottomArray.length > 0 &&
//             bottomArray.map((item, index) => {
//               if (item.bgimage && item.sectiontitle !== "Twitter") {
//                 return (
//                   <BottomSectionCard
//                     array={bottomArray}
//                     index={index}
//                     item={item}
//                     css={css`
//                       width: ${item.buttonvalue ? "27%" : "47.5%"};
//                       display: flex;
//                     `}
//                   >
//                     <ProductImageBackgroundImage
//                       css={css`
//                         object-fit: contain;
//                       `}
//                       fluid={renderImage(item.bgimage, item.mobileBgImage)}
//                       alt="img-piktorlabs-header"
//                     >
//                       <Link
//                         css={css`
//                           width: 100%;
//                           height: 100%;
//                         `}
//                         to={item.link ? item.link : "/"}
//                       >
//                         <SectionCardWrapper
//                           css={css`
//                             padding: 4rem 2.5rem;
//                             justify-content: center;
//                             @media ${medium} {
//                               padding: 2rem;
//                             }
//                             @media ${small} {
//                               padding: 5%;
//                               padding-bottom: 45px;
//                             }
//                           `}
//                           index={index}
//                         >
//                           {item.product_logo_image && (
//                             <Img
//                               fluid={item.product_logo_image}
//                               imgStyle={{
//                                 "object-fit": "contain",
//                                 width: "unset",
//                               }}
//                               css={logoImageStyle}
//                             />
//                           )}
//                           {item.headertext && (
//                             <newGlobalStyles.body2
//                               css={css`
//                                 text-transform: uppercase;
//                                 font-weight: bold;
//                                 margin-bottom: 0.5rem;
//                                 color: #343434;
//                                 line-height: 1.14;
//                                 width: max-content;
//                                 padding-top: 2rem;
//                               `}
//                             >
//                               {item.headertext}
//                             </newGlobalStyles.body2>
//                           )}
//                           {item.sectiontitle && (
//                             <SideSectionTitle
//                               css={css`
//                                 width: 100%;
//                                 margin-bottom: ${item.buttonvalue
//                                   ? "0.5rem"
//                                   : "2rem"};
//                                 font-size: ${item.buttonvalue
//                                   ? "24px"
//                                   : "2.5rem"};
//                                 @media ${small} {
//                                   font-size: ${item.buttonvalue
//                                     ? "24px"
//                                     : "35px"};
//                                   margin-bottom: 0;
//                                 }
//                               `}
//                               item={item}
//                               index={index}
//                             >
//                               {item.sectiontitle}
//                             </SideSectionTitle>
//                           )}
//                           {item.buttonvalue && (
//                             <Link to={item.link ? item.link : "/"}>
//                               <newGlobalStyles.submitButton
//                                 css={css`
//                                   text-transform: uppercase;
//                                 `}
//                                 color="#3452f2"
//                                 bgColor="#fff"
//                                 align="left"
//                                 padding="0 0"
//                                 hoverColor="#3452f2"
//                                 hoverBgColor="inherit"
//                                 activeBgColor="#8381ff"
//                                 border="none"
//                                 css={css`
//                                   min-width: 9.2rem;
//                                 `}
//                               >
//                                 {item.buttonvalue}
//                               </newGlobalStyles.submitButton>
//                             </Link>
//                           )}
//                           {item.tagline && (
//                             <newGlobalStyles.body2 css={TagLineContent}>
//                               {parse(item.tagline)}
//                             </newGlobalStyles.body2>
//                           )}
//                         </SectionCardWrapper>
//                       </Link>
//                     </ProductImageBackgroundImage>
//                   </BottomSectionCard>
//                 )
//               } else if (!item.bgimage && item.sectiontitle !== "Twitter") {
//                 return (
//                   <BottomSectionCard
//                     array={bottomArray}
//                     index={index}
//                     item={item}
//                     css={css`
//                       width: ${item.buttonvalue ? "27%" : "47.65%"};
//                       @media ${xxLarge} {
//                         width: ${item.buttonvalue ? "27%" : "47.7%"};
//                       }
//                     `}
//                   >
//                     <SectionCardWrapper
//                       css={css`
//                         padding: 1.5rem;
//                         justify-content: ${item.buttonvalue
//                           ? "space-around"
//                           : "center"};
//                         @media ${medium} {
//                           padding: 2rem;
//                         }
//                         @media ${small} {
//                           padding: 5%;
//                         }
//                       `}
//                     >
//                       {item.product_logo_image && (
//                         <Img
//                           fluid={item.product_logo_image}
//                           imgStyle={{
//                             "object-fit": "contain",
//                             width: "unset",
//                           }}
//                           css={logoImageStyle}
//                         />
//                       )}
//                       {item.headertext && (
//                         <newGlobalStyles.body2
//                           css={css`
//                             text-transform: uppercase;
//                             font-weight: bold;
//                             margin-bottom: 0.5rem;
//                             color: ${item.buttonvalue
//                               ? "#474747"
//                               : "#fff"} !important;
//                             line-height: 1.14;
//                           `}
//                         >
//                           {item.headertext}
//                         </newGlobalStyles.body2>
//                       )}
//                       {item.sectiontitle && (
//                         <Link
//                           to={item.link ? item.link : "/"}
//                           css={css`
//                             &:hover {
//                               cursor: pointer};
//                             }
//                           `}
//                         >
//                           <SideSectionTitle
//                             css={css`
//                               width: 100%;
//                               font-size: ${item.buttonvalue
//                                 ? "24px"
//                                 : "2.5rem"};
//                               padding-left: 1rem;
//                               padding-top: ${item.buttonvalue ? "22%" : "0"};
//                               @media ${small} {
//                                 font-size: ${item.buttonvalue
//                                   ? "24px"
//                                   : "35px"};
//                                 margin-bottom: 0;
//                                 padding: ${item.buttonvalue
//                                   ? "3rem 0 0 0"
//                                   : "0"};
//                               }
//                               @media ${ipadPro} {
//                                 padding-left: ${item.buttonvalue
//                                   ? "0"
//                                   : "2rem"};
//                                 padding-top: ${item.buttonvalue ? "6rem" : "0"};
//                               }
//                               @media ${xxLarge} {
//                                 padding-top: ${item.buttonvalue ? "6rem" : "0"};
//                                 padding-left: ${item.buttonvalue
//                                   ? "1rem"
//                                   : "3rem"};
//                               }
//                               @media only all and (min-width: 2280px) {
//                                 padding-top: ${item.buttonvalue
//                                   ? "6rem"
//                                   : "5%"};
//                               }
//                               @media only all and (min-width: 1802px) and (max-width: 2280px) {
//                                 padding-top: ${item.buttonvalue
//                                   ? "6rem"
//                                   : "5%"};
//                               }
//                             `}
//                             item={item}
//                             index={index}
//                           >
//                             {item.sectiontitle}
//                           </SideSectionTitle>
//                         </Link>
//                       )}
//                       {item.buttonvalue && (
//                         <Link to={item.link ? item.link : "/"}>
//                           <newGlobalStyles.submitButton
//                             css={css`
//                               text-transform: uppercase;
//                             `}
//                             color="#474747"
//                             bgColor="inherit"
//                             padding="0"
//                             hoverColor="#5451ff"
//                             hoverBgColor="inherit"
//                             activeBgColor="#8381ff"
//                             border="none"
//                             css={css`
//                               float: right;
//                               padding: 1rem 1rem 0 0;
//                               @media ${ipadPro} {
//                                 padding: 4rem 0 0 0;
//                               }
//                             `}
//                           >
//                             {item.buttonvalue}
//                           </newGlobalStyles.submitButton>
//                         </Link>
//                       )}
//                       {item.tagline && (
//                         <newGlobalStyles.body2 css={TagLineContent}>
//                           {parse(item.tagline)}
//                         </newGlobalStyles.body2>
//                       )}
//                     </SectionCardWrapper>
//                   </BottomSectionCard>
//                 )
//               } else if (!item.bgimage && item.sectiontitle === "Twitter") {
//                 return (
//                   <BottomSectionCard
//                     css={css`
//                       padding: 2.5rem 1.875rem;
//                       position: relative;
//                       width: ${item.buttonvalue ? "27%" : "47.5%"};
//                       color: ${item.buttonvalue
//                         ? "#474747"
//                         : "#fff"} !important;
//                       @media ${medium} {
//                         padding: 2rem;
//                       }
//                       @media ${small} {
//                         padding: 5%;
//                       }
//                     `}
//                     array={bottomArray}
//                     index={index}
//                     item={item}
//                     mobHeight={"15.625rem"}
//                   >
//                     {parse(item.twittercontent)}
//                     <img
//                       src={TwitterLogo}
//                       css={css`
//                         width: 1rem;
//                         position: absolute;
//                         right: 1.875rem;
//                         top: 3rem;
//                         @media ${smallAndMedium} {
//                           right: 1.875rem;
//                           top: 2.5rem;
//                         }
//                         @media ${semiLarge} {
//                           right: 1.875rem;
//                           top: 3.65rem;
//                         }
//                         @media ${semiLargeAndLarge} {
//                           right: 1.875rem;
//                           top: 3.25rem;
//                         }
//                       `}
//                     />
//                   </BottomSectionCard>
//                 )
//               }
//             })}
//         </BottomSectionContainer>
//       </HomePageContainer>
//     </Layout>
//   )
// }

// export default IndexPage

import React, { useEffect, useState } from "react"
import "../index.css"
import Ustlogo from "../assets/logos/UST-evolve-logo.svg"
import PiktorLogo from "../assets/logos/piktorlabs-logo-updated.svg"

function IndexPage() {
  const [counter, updateCounter] = useState(4)
  const [coverClass, updateCoverClass] = useState("cover")
  const [middleDivClass, updateMiddleDivClass] = useState("middle-div")
  useEffect(() => {
    if (counter >= 0) {
      setTimeout(() => {
        if (counter > 0) updateCounter(counter - 1)
        if (counter === 4) {
          updateCoverClass("cover fadeOut-four")
        }
        // if (counter === 4) {
        //   updateCoverClass("cover fadeOut-three")
        // } else if (counter === 3) {
        //   // updateCoverClass("cover fadeOut-two")
        // } else if (counter === 2) {
        //   updateCoverClass("cover fadeOut-one")
        // } else
        else if (counter === 1) {
          // updateCoverClass("cover fadeOut-zero")
          updateMiddleDivClass("middle-div middle-div-to-center")
        } else if (counter === 0) {
          window.location.replace("https://www.ust.com/en/evolve")
        }
      }, 2000)
    }
  })

  return (
    <div className="App">
      <div className={coverClass}></div>
      <div className="logo">
        <img src={Ustlogo} />
      </div>

      <div className={middleDivClass}>
        <div className="vertical-line">
          <img src={PiktorLogo} />
        </div>
        <div className="right-div">
          <div className="right-div-header">
            Farewell, Piktorlabs! Now part of UST Evolve.
          </div>
          <div className="right-div-middle">
            <p>{counter}</p>
          </div>
          <div className="right-div-footer">
            click <a href="https://www.ust.com/en/evolve">here</a> to visit the
            UST home page.
          </div>
        </div>
      </div>
    </div>
  )
}

export default IndexPage
